import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { Client } from "@notionhq/client/build/src";
// const notion = new Client({
//   auth: "secret_2hgDsQSjzWrcSYnU0z9SVCWqKBAFsoDbutQFgrcEvHU",
// });

// async function getDatabase() {
//   const response = await notion.databases.retrieve({
//     database_id: "c699985f10994684941e17cd382a1259",
//   });
//   console.log(response);
// }

// console.log(getDatabase.response);

// const initialValues = {
//   firstName: "",
//   lastName: "",
//   contactDetails: "",
//   emailAddress: "",
// };

// const onSubmit = (values) => {
//   notion.pages.create({
//     parent: { database_id: "c699985f10994684941e17cd382a1259" },

//     properties: {
//       title: [{
//         type: "text",
//         text: {
//           content: values.firstName
//         }
//       }],
//     },
//   });
// };

// const validationSchema = Yup.object({
//   firstName: Yup.string().required("Required"),
//   lastName: Yup.string().required("Required"),
//   contactDetails: Yup.string().required("Required"),
//   emailAddress: Yup.string()
//     .email("Invalid E-Mail Format")
//     .required("Required"),
// });

const ContactUs = () => {
  return (
    <>
    <SEO 
    title = "Contact Us - Kabira Mobility"/>
      <Layout>
        <main>
          <section className="md:max-w-screen-md lg:max-w-screen-lg mx-auto py-12">
            <h1 className="text-center text-5xl font-bold text-gray-700">
              Contact Us
            </h1>
            <div className="max-w-screen-sm mx-auto my-10">

              <embed className = "w-full h-[800px]" src="https://commotion.page/embed/neIJAOasvFYiAtyNHDt8" type="" />
          
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default ContactUs;
